<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card>
          <div class="mx-1 pt-2 d-flex justify-content-between">
            <b class="font-large-1">
              Name: <span class="text-primary">{{ this.$route.params.name }}</span></b
            >
            <b class="font-large-1">
              Logpoint balance: <b-button variant="primary">{{ bal }}</b-button></b
            >
          </div>
          <b-button variant="primary" class="ml-1 mt-2" @click="$refs.handleAddLP.show()">Add/Deduct Logpoints</b-button>
          <MyTable
            :columns="columns"
            :rows="rows"
            :isLoading="isLoading"
            :customButton="customButton"
            @customButton="handleAddLP"
            @getLogPointLogs="getLogPointLogs"
          >
            <template v-slot="{ props }">
              <span v-if="props.column.field === 'id'">
                <b-button variant="gradient-primary" block>
                  {{ !props.row.id ? 'N/A' : props.row.id }}
                </b-button>
              </span>
              <span v-if="props.column.field === 'type'">
                <b-button v-if="props.row.type === 'RESTORE'" variant="gradient-success" block>
                  {{ props.row.type }}
                </b-button>
                <b-button v-else variant="gradient-danger" block>
                  {{ !props.row.type ? '-' : props.row.type }}
                </b-button>
              </span>
              <div v-if="props.column.field === 'logpoints_reloaded'">
                <span v-if="props.row.type === 'RESTORE'">
                  {{ !props.row.logpoint ? '-' : $helpers.formatTotal(props.row.logpoint) }}
                </span>
                <span v-else> - </span>
              </div>
              <div v-if="props.column.field === 'logpoints_deducted'">
                <span v-if="props.row.type === 'DEDUCT'">
                  {{ !props.row.logpoint ? '-' : $helpers.formatTotal(props.row.logpoint) }}
                </span>
                <span v-else> - </span>
              </div>
              <div v-if="props.column.field === 'comment'">
                {{ !props.row.comment ? '-' : props.row.comment }}
              </div>
              <div v-if="props.column.field === 'remarks'">
                {{ !props.row.added_by ? 'None' : props.row.added_by }}
              </div>
              <div v-if="props.column.field === 'created_at'">
                {{ !props.row.date_added ? '-' : props.row.date_added.split('T')[0].substr(0, 10) }}
              </div>
            </template>
          </MyTable>
        </b-card>
      </b-col>
    </b-row>
    <ValidationObserver ref="addLogpoint">
      <b-modal
        size="lg"
        ref="handleAddLP"
        centered
        title="ADD / REDUCE LP"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col cols="6" class="pr-0 d-flex justify-content-center align-items-center">
                    <div class="d-flex justify-content-center">
                      <b-form-radio
                        v-model="addPayload.action_type"
                        name="Add"
                        value="RESTORE"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio v-model="addPayload.action_type" name="Reduce" value="DEDUCT">
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider v-slot="{ errors }" name="LP Amount" rules="required">
                        <input
                          v-model="addPayload.logpoint"
                          type="number"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  class="w-100"
                  rules="required"
                >
                  <input
                    v-model="addPayload.remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button @click.prevent="addOrReduceLP()" variant="gradient-primary" type="submit">
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
    <ValidationObserver ref="editLogpoint">
      <b-modal
        size="lg"
        ref="handleEditLP"
        centered
        title="EDIT LOGPOINT"
        hide-footer
        no-close-on-backdrop
      >
        <b-form>
          <b-row>
            <b-col>
              <b-form-group label="Amount LP" class="w-100">
                <b-row>
                  <b-col cols="6" class="pr-0 d-flex justify-content-center align-items-center">
                    <div class="d-flex justify-content-center">
                      <b-form-radio
                        v-model="editPayload.action_type"
                        name="Add"
                        value="RESTORE"
                        class="mr-2"
                      >
                        Add
                      </b-form-radio>
                      <b-form-radio v-model="editPayload.action_type" name="Reduce" value="DEDUCT">
                        Reduce
                      </b-form-radio>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <div>
                      <ValidationProvider v-slot="{ errors }" name="LP Amount" rules="required">
                        <input
                          v-model="editPayload.logpoint"
                          type="number"
                          class="form-control font-small-3"
                          placeholder="LP Amount"
                          aria-label="LP Amount"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Remarks" class="w-100">
                <ValidationProvider
                  #default="{ errors }"
                  name="Remarks"
                  class="w-100"
                  rules="required"
                >
                  <input
                    v-model="editPayload.remarks"
                    type="text"
                    class="form-control font-small-3"
                    placeholder="Remarks"
                    aria-label="Remarks"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="my-2 d-flex justify-content-center">
            <b-button @click.prevent="editLogPoint()" variant="gradient-primary" type="submit">
              SUBMIT
            </b-button>
          </b-row>
        </b-form>
      </b-modal>
    </ValidationObserver>
  </b-container>
</template>

<script>
import { MyTable } from '@/components'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    flatPickr,
    MyTable,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isLoading: true,
      bal: 0,
      customButton: {
        label: 'Add / Reduce LP',
        icon: 'PlusCircleIcon',
      },
      addPayload: {
        user_id: this.$route.params.id,
        logpoint: null,
        action_type: '',
        remarks: '',
      },
      editPayload: {
        user_id: null,
        logpoint: null,
        action_type: '',
        remarks: '',
      },
      columns: [
        {
          label: 'Transaction ID',
          field: 'id',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Type',
          field: 'type',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Logpoints Reloaded',
          field: 'logpoints_reloaded',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Logpoints Deducted',
          field: 'logpoints_deducted',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Comment',
          field: 'comment',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Added By',
          field: 'remarks',
          tdClass: 'text-center align-middle',
        },
        {
          label: 'Created At',
          field: 'created_at',
          tdClass: 'text-center align-middle',
        }
      ],
      rows: [],
      required,
    }
  },

  created() {
    this.getLogPointLogs()
  },

  methods: {
    getLogPointLogs() {
      this.isLoading = true
      this.$http
        .get(`logpoint/total_users_logpoints?user_id=${this.$route.params.id}`)
        .then((response) => {
          this.bal = response.data.data.logpoint
        })
      this.$http
        .get(`logpoint/all_logpoint_logs?type=all&id=${this.$route.params.id}`)
        .then((response) => {
          if (response.data.status) {
            this.rows = response.data.data
          } else {
            this.$swal({
              title: 'Error !',
              icon: 'warning',
              text: response.data.message,
              confirmButtonText: 'OK',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
              buttonsStyling: false,
            })
          }
          this.isLoading = false
        })
    },

    handleAddLP() {
      this.$refs.handleAddLP.show()
    },

    addOrReduceLP() {
      this.$refs.addLogpoint.validate().then((success) => {
        if (success) {
          this.addPayload.user_id = this.$route.params.id
          const balWithoutCommas = this.bal.replace(/,/g, '');
          if (this.addPayload.action_type != 'DEDUCT') {
            this.isLoading = true
            const payload = new FormData()

            for (const key in this.addPayload) {
              payload.append(key, this.addPayload[key])
            }

            this.$http.post(`logpoints`, payload).then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: 'Success !',
                  icon: 'success',
                  text: response.data.message,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.getLogPointLogs()
                    this.$refs.handleAddLP.hide()
                    this.addPayload = {}
                  }
                })
              } else if (response.data.errors) {
                this.$refs.addLogpoint.setErrors(response.data.errors)
              } else {
                this.$swal({
                  title: 'Error !',
                  icon: 'warning',
                  text: response.data.message,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
              }
              this.isLoading = false
            })
          } else if (
            this.addPayload.action_type == 'DEDUCT' &&
            parseFloat(balWithoutCommas) >= parseFloat(this.addPayload.logpoint)
          ) {
            this.isLoading = true
            const payload = new FormData()

            for (const key in this.addPayload) {
              payload.append(key, this.addPayload[key])
            }

            this.$http.post(`logpoints`, payload).then((response) => {
              if (response.data.status) {
                this.$swal({
                  title: 'Success !',
                  icon: 'success',
                  text: response.data.message,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.getLogPointLogs()
                    this.$refs.handleAddLP.hide()
                    this.addPayload = {}
                  }
                })
              } else if (response.data.errors) {
                this.$refs.addLogpoint.setErrors(response.data.errors)
              } else {
                this.$swal({
                  title: 'Error !',
                  icon: 'warning',
                  text: response.data.message,
                  confirmButtonText: 'OK',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                  buttonsStyling: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    this.$router.go(0)
                  }
                })
              }
              this.isLoading = false
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Logpoint cannot be negative!',
                icon: 'XIcon',
                variant: 'warning',
              },
            })
          }
        }
      })
    },

    handleEditLP(id, index) {
      const row = this.rows[index]
      this.editPayload = {
        id: id,
        logpoint: row.logpoint,
        type: row.type,
        remarks: row.remarks,
      }
      this.$refs.handleEditLP.show()
    },

    editLogPoint() {
      this.$refs.editLogpoint.validate().then((success) => {
        if (success) {
          this.isLoading = true
          const payload = new FormData()

          for (const key in this.editPayload) {
            payload.append(key, this.editPayload[key])
          }

          this.$http.post(`edit_logpoint`, payload).then((response) => {
            if (response.data.status) {
              this.$swal({
                title: 'Success !',
                icon: 'success',
                text: response.data.message,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.getLogPointLogs()
                  this.$refs.handleEditLP.hide()
                }
              })
            } else if (response.data.errors) {
              this.$refs.editLogpoint.setErrors(response.data.errors)
            } else {
              this.$swal({
                title: 'Error !',
                icon: 'warning',
                text: response.data.message,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$router.go(0)
                }
              })
            }
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>
